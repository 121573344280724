import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { setup, assign } from "xstate";

interface Context {
  totalSteps: number;
  currentStepValue: number;
  transitionDuration: number;
}

export const eventNames = {
  PROCEED_TO_NEXT_STEP: "PROCEED_TO_NEXT_STEP",
};

const shouldSkipHealthPlanScreen = isFeatureEnabled(
  FeatureToggleIdentifier.ENABLE_SENS_UPDATES
);

const initialContextValues: Context = {
  totalSteps: shouldSkipHealthPlanScreen ? 4 : 5,
  currentStepValue: 1,
  transitionDuration: 3,
};

export const CVPFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as { type: "PROCEED_TO_NEXT_STEP" },
  },
  guards: {
    shouldSkipHealthPlanScreen: function () {
      return shouldSkipHealthPlanScreen;
    },
  },
}).createMachine({
  context: initialContextValues,
  id: "CVPFlow",
  initial: "CVPHealthCheck",
  states: {
    CVPHealthCheck: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPHealthScore",
        },
      },
      entry: assign(initialContextValues),
    },
    CVPHealthScore: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPMedicalAssistance",
        },
      },
      entry: assign({
        currentStepValue: 2,
      }),
    },
    CVPMedicalAssistance: {
      on: {
        PROCEED_TO_NEXT_STEP: [
          {
            target: "CVPHealthPlan",
          },
          {
            target: "CVPLastStep",
            guard: "shouldSkipHealthPlanScreen",
          },
        ],
      },
      entry: assign({ currentStepValue: 3 }),
    },
    CVPHealthPlan: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPLastStep",
        },
      },
      entry: assign({ currentStepValue: 4 }),
    },
    CVPLastStep: {
      entry: assign({ currentStepValue: shouldSkipHealthPlanScreen ? 4 : 5 }),
    },
  },
});
